import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "New Era",
  date: new Date(2024, 11, 29),
  img: "/img/2024.jpeg",
  tracks: [
    {
      title: "Morning Surf Party",
      src: "/songs/2024-12-29/morning_surf_party.mp3",
      duration: 290.472,
    },
    {
      title: "Partygeddon",
      src: "/songs/2024-12-29/partygeddon.mp3",
      duration: 505.152,
    },
    {
      title: "RIP Jimmy Carter",
      src: "/songs/2024-12-29/rip_jimmy_carter.mp3",
      duration: 736.704,
    },
    {
      title: "Skinny Jeans",
      src: "/songs/2024-12-29/skinny_jeans.mp3",
      duration: 398.232,
    },
    {
      title: "Paper Plates",
      src: "/songs/2024-12-29/paper_plates.mp3",
      duration: 565.2,
    },
    {
      title: "Destroying The Place",
      src: "/songs/2024-12-29/destroying_the_place.mp3",
      duration: 1010.76,
    },
    {
      title: "Play Ball",
      src: "/songs/2024-12-29/play_ball.mp3",
      duration: 724.92,
    },
    {
      title: "Stepping Stone",
      src: "/songs/2024-12-29/stepping_stone.mp3",
      duration: 772.608,
    },
    {
      title: "Beach Vacation",
      src: "/songs/2024-12-29/beach_vacation.mp3",
      duration: 447.576,
    },
    {
      title: "Bring Out The Dead",
      src: "/songs/2024-12-29/bring_out_the_dead.mp3",
      duration: 562.8,
    },
    {
      title: "Remember When",
      src: "/songs/2024-12-29/remember_when.mp3",
      duration: 483.528,
    },
    {
      title: "Pedal To The Metal",
      src: "/songs/2024-12-29/pedal_to_the_metal.mp3",
      duration: 874.464,
    },
    {
      title: "I'm The Asshole",
      src: "/songs/2024-12-29/im_the_asshole.mp3",
      duration: 410.232,
    },
    {
      title: "Foxy Lady",
      src: "/songs/2024-12-29/foxy_lady.mp3",
      duration: 525.936,
    },
    {
      title: "Four Miles High",
      src: "/songs/2024-12-29/four_miles_high.mp3",
      duration: 931.032,
    },
    {
      title: "Insatiable",
      src: "/songs/2024-12-29/insatiable.mp3",
      duration: 813.744,
    },
    {
      title: "Wrong Side Of The Tracks",
      src: "/songs/2024-12-29/wrong_side_of_the_tracks.mp3",
      duration: 368.208,
    },
    {
      title: "Destination Funkytown",
      src: "/songs/2024-12-29/destination_funkytown.mp3",
      duration: 583.992,
    },
    {
      title: "Closer To You",
      src: "/songs/2024-12-29/closer_to_you.mp3",
      duration: 1234.8,
    },
  ],
};
export default album;
