import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "We Are The People",
  date: new Date(2025, 0, 19),
  img: "/img/punk-declaration.jpeg",
  tracks: [
    {
      title: "Deep Sea Diving",
      src: "/songs/2025-01-19/deep_sea_diving.mp3",
      duration: 410.952,
    },
    {
      title: "Glasses Of Molasses",
      src: "/songs/2025-01-19/glasses_of_molasses.mp3",
      duration: 545.808,
    },
    {
      title: "Walking Through Space-Time",
      src: "/songs/2025-01-19/walking_through_space_time.mp3",
      duration: 1072.632,
    },
    {
      title: "Hanky Panky",
      src: "/songs/2025-01-19/hanky_panky.mp3",
      duration: 520.464,
    },
    {
      title: "Half The Battle",
      src: "/songs/2025-01-19/half_the_battle.mp3",
      duration: 502.176,
    },
    {
      title: "Maneuver Ability",
      src: "/songs/2025-01-19/maneuver_ability.mp3",
      duration: 217.968,
    },
    {
      title: "The Currency Of Time",
      src: "/songs/2025-01-19/the_currency_of_time.mp3",
      duration: 529.392,
    },
    {
      title: "Family Vacation",
      src: "/songs/2025-01-19/family_vacation.mp3",
      duration: 329.688,
    },
    {
      title: "Easy Does It",
      src: "/songs/2025-01-19/easy_does_it.mp3",
      duration: 839.064,
    },
    {
      title: "Free Quincy",
      src: "/songs/2025-01-19/free_quincy.mp3",
      duration: 624.48,
    },
    {
      title: "I Stepped On Your Dick",
      src: "/songs/2025-01-19/i_stepped_on_your_dick.mp3",
      duration: 787.992,
    },
    {
      title: "Right Of Way",
      src: "/songs/2025-01-19/right_of_way.mp3",
      duration: 536.352,
    },
    {
      title: "Glass Bottom Boat",
      src: "/songs/2025-01-19/glass_bottom_boat.mp3",
      duration: 770.712,
    },
    {
      title: "No Better Time",
      src: "/songs/2025-01-19/no_better_time.mp3",
      duration: 1262.424,
    },
    {
      title: "Yesteryear",
      src: "/songs/2025-01-19/yesteryear.mp3",
      duration: 422.664,
    },
    {
      title: "Answering The Call",
      src: "/songs/2025-01-19/answering_the_call.mp3",
      duration: 1839.072,
    },
    {
      title: "Caught Red Handed",
      src: "/songs/2025-01-19/caught_red_handed.mp3",
      duration: 916.2,
    },
  ],
};
export default album;
