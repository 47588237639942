import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Two Steps Forward",
  date: new Date(2025, 0, 12),
  img: "/img/aircraft.jpeg",
  tracks: [
    {
      title: "Mountain Thunder",
      src: "/songs/2025-01-12/mountain_thunder.mp3",
      duration: 560.904,
    },
    {
      title: "Blurred Lines",
      src: "/songs/2025-01-12/blurred_lines.mp3",
      duration: 695.448,
    },
    {
      title: "Class Act",
      src: "/songs/2025-01-12/class_act.mp3",
      duration: 519,
    },
    {
      title: "Maiden Flight",
      src: "/songs/2025-01-12/maiden_flight.mp3",
      duration: 880.968,
    },
    {
      title: "Toy Box",
      src: "/songs/2025-01-12/toy_box.mp3",
      duration: 569.376,
    },
    {
      title: "Motherly Warnings",
      src: "/songs/2025-01-12/motherly_warnings.mp3",
      duration: 706.32,
    },
    {
      title: "Naval Exercises",
      src: "/songs/2025-01-12/naval_exercises.mp3",
      duration: 1037.472,
    },
    {
      title: "Dark Days",
      src: "/songs/2025-01-12/dark_days.mp3",
      duration: 898.68,
    },
    {
      title: "Complicated Legacy",
      src: "/songs/2025-01-12/complicated_legacy.mp3",
      duration: 1027.368,
    },
    {
      title: "Fart Joke",
      src: "/songs/2025-01-12/fart_joke.mp3",
      duration: 813.72,
    },
    {
      title: "Dew Drops",
      src: "/songs/2025-01-12/dew_drops.mp3",
      duration: 1134.168,
    },
    {
      title: "Sitting Down",
      src: "/songs/2025-01-12/sitting_down.mp3",
      duration: 471.024,
    },
    {
      title: "Private Jet",
      src: "/songs/2025-01-12/private_jet.mp3",
      duration: 573.624,
    },
    {
      title: "Five Minutes",
      src: "/songs/2025-01-12/five_minutes.mp3",
      duration: 388.344,
    },
    {
      title: "Last Call",
      src: "/songs/2025-01-12/last_call.mp3",
      duration: 678.312,
    },
  ],
};
export default album;
